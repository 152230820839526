/** Vue Font Awesome component: https://github.com/FortAwesome/vue-fontawesome */
import Vue from "vue";
import { library, config } from "@fortawesome/fontawesome-svg-core";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";
import {
  faBackpack as fadBackpack,
  faSchool as fadSchool,
  faCircleQuestion as fadCircleQuestion,
  faCalendarClock as fadCalendarClock,
  faFilePen as fadFilePen,
  faBullhorn as fadBullhorn,
  faBookOpenCover as fadBookOpenCover,
  faChalkboard as fadChalkboard,
  faPersonChalkboard as fadPersonChalkboard,
  faCubes as fadCubes,
  faCommentsQuestionCheck as fadCommentsQuestionCheck,
  faBooks as fadBooks,
  faVideo as fadVideo,
  faMessages as fadMessages,
  faEnvelopeDot as fadEnvelopeDot,
  faScrewdriverWrench as fadScrewdriverWrench,
  faThumbsUp as fadThumbsUp,
  faComputer as fadComputer,
  faPenField as fadPenField,
  faBookCopy as fadBookCopy,
  faUserGraduate as fadUserGraduate,
  faClipboardList as fadClipboardList,
} from "@fortawesome/pro-duotone-svg-icons";
import {
  faArrowDownToBracket as farArrowDownToBracket,
  faFile as farFile,
  faListUl as farListUl,
  faBookmark as farBookmark,
  faThumbsUp as farThumbsUp,
  faComments as farComments,
  faCircleXmark as farCircleXmark,
  faBinoculars as farBinoculars,
  faPlay as farPlay,
  faKey as farKey,
  faColumns3 as farColumns3,
  faTable as farTable,
  faFilePdf as farFilePDF,
  faSquarePollVertical as farSquarePollVertical,
  faClipboardList as farClipboardList,
  faArrowUpRightFromSquare as farArrowUpRightSquare,
  faFileMagnifyingGlass as farFileMagnifyingGlass,
  faEnvelope as farEnvelope,
  faEdit as farEdit,
  faFileArrowDown as farFileArrowDown,
  faXmark as farXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faAppleWhole as fasAppleWhole,
  faBooks as fasBooks,
  faCircle as fasCircle,
  faChevronDown as fasChevronDown,
  faInboxOut as fasInboxOut,
  faCaretRight as fasCaretRight,
  faFolder as fasFolder,
  faLockOpen as fasLockOpen,
  faLock as fasLock,
  faCircleEllipsis as fasCircleEllipsis,
  faCubes as fasCubes,
  faCommentsQuestionCheck as fasCommentsQuestionCheck,
  faBookOpenCover as fasBookOpenCover,
  faChalkboard as fasChalkboard,
  faPersonChalkboard as fasPersonChalkboard,
  faCircleQuestion as fasCircleQuestion,
  faBullhorn as fasBullhorn,
  faCalendarClock as fasCalendarClock,
  faFilePen as fasFilePen,
  faBookmark as fasBookmark,
  faCircleXmark as fasCircleXmark,
  faPaperPlaneTop as fasPaperPlaneTop,
  faPencil as fasPencil,
  faBuildingFlag as fasBuildingFlag,
  faSchool as fasSchool,
  faBook as fasBook,
  faGear as fasGear,
  faShare as fasShare,
  faPrint as fasPrint,
  faPowerOff as fasPowerOff,
  faUser as fasUser,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faPlus as fasPlus,
  faTrash as fasTrash,
  faCopy as fasCopy,
  faSquareCheck as fasSquareCheck,
  faUpload as fasUpload,
  faCaretDown as fasCaretDown,
  faChartLineUp as fasChartLineUp,
  faScreenUsers as fasScreenUsers,
  faListCheck as fasListCheck,
  faKeyboard as fasKeyboard,
  faKeyboardDown as fasKeyboardDown,
  faRobot as fasRobot,
  faBoxArchive as fasBoxArchive,
  faDiagramSubtask as fasDiagramSubtask,
  faCircleCaretDown as fasCircleCaretDown,
  faDash as fasDash,
  faCheck as fasCheck,
  faCircleDashed as fasCircleDashed,
  faStarCircle as fasStarCircle,
  faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter,
  faBan as fasBan,
  faXmark as fasXmark,
  faRotateRight as fasRotateRight,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faMagnifyingGlass as fasMagnifyingGlass,
  faChevronUp as fasChevronUp,
} from "@fortawesome/pro-solid-svg-icons";

config.autoAddCss = false;

// Add icons using this plugin
const regular = [
  farArrowDownToBracket,
  farFile,
  farListUl,
  farBookmark,
  farThumbsUp,
  farComments,
  farCircleXmark,
  farBinoculars,
  farPlay,
  farKey,
  farColumns3,
  farTable,
  farFilePDF,
  farSquarePollVertical,
  farClipboardList,
  farArrowUpRightSquare,
  farFileMagnifyingGlass,
  farEnvelope,
  farEdit,
  farFileArrowDown,
  farXmark,
];
const solid = [
  fasAppleWhole,
  fasBooks,
  fasCircle,
  fasChevronDown,
  fasInboxOut,
  fasCubes,
  fasCommentsQuestionCheck,
  fasBookOpenCover,
  fasChalkboard,
  fasPersonChalkboard,
  fasCircleQuestion,
  fasBullhorn,
  fasCalendarClock,
  fasFilePen,
  fasBookmark,
  fasCaretRight,
  fasFolder,
  fasLock,
  fasLockOpen,
  fasCircleEllipsis,
  fasCircleXmark,
  fasPaperPlaneTop,
  fasPencil,
  fasBuildingFlag,
  fasSchool,
  fasBook,
  fasGear,
  fasShare,
  fasPrint,
  fasPowerOff,
  fasUser,
  fasArrowLeft,
  fasArrowRight,
  fasPlus,
  fasTrash,
  fasCopy,
  fasSquareCheck,
  fasUpload,
  fasCaretDown,
  fasChartLineUp,
  fasScreenUsers,
  fasListCheck,
  fasKeyboard,
  fasKeyboardDown,
  fasRobot,
  fasBoxArchive,
  fasDiagramSubtask,
  fasCircleCaretDown,
  fasDash,
  fasCheck,
  fasCircleDashed,
  fasStarCircle,
  fasUpRightAndDownLeftFromCenter,
  fasBan,
  fasXmark,
  fasRotateRight,
  fasArrowUpRightFromSquare,
  fasMagnifyingGlass,
  fasChevronUp,
];
const duotone = [
  fadBackpack,
  fadSchool,
  fadCircleQuestion,
  fadCalendarClock,
  fadFilePen,
  fadBullhorn,
  fadBookOpenCover,
  fadChalkboard,
  fadPersonChalkboard,
  fadCubes,
  fadCommentsQuestionCheck,
  fadBooks,
  fadVideo,
  fadMessages,
  fadEnvelopeDot,
  fadScrewdriverWrench,
  fadThumbsUp,
  fadComputer,
  fadPenField,
  fadBookCopy,
  fadUserGraduate,
  fadClipboardList,
];
library.add(...duotone, ...regular, ...solid);

Vue.component("fa", FontAwesomeIcon);
Vue.component("fa-layers", FontAwesomeLayers);
