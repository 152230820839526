
import hubspotMixin from "~/mixins/hubspotMixin.js";
export default {
    mixins: [hubspotMixin],
    methods: {
        refresh() {
            window.location.reload(true);
        },
        markAsWatched() {
            localStorage.setItem("freeTrialVideo", `${this.user.id}`);
            this.$nuxt.$emit("free-trial-video-watched");
        }
    },
    async middleware({ store, redirect, route, $models }) {
        // get students and sections on the server
        if (!store.state.loaded && !store.state.students.length) {
            return $models.Students.get().then(data => {
                store.commit("students", data.students);
                store.commit("sections", data.sections);
                store.commit("schools", data.schools);
                store.commit("hasPastSections", data.hasPastSections);
            });
        }
    },
    computed: {
        accessibe() {
            return this.$store.state.user.accessibe;
        },
        user() {
            return this.$store.state.user;
        },
        storageUrl() {
            return process.env.STORAGE_URL;
        },
        login() {
            return process.env.LOGIN_URL;
        },
        videoLink() {
            // Check what option is set for user and get corresponding link
            if (this.$store.state.user.settings.v1 && this.$store.state.user.settings.v2) {
                return "https://player.vimeo.com/video/766719849?h=9a7c143e31&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
            }
            if (this.$store.state.user.settings.v1) {
                return "https://player.vimeo.com/video/685647888?h=a074d8476f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
            }
            return "https://player.vimeo.com/video/685649447?h=ed76f9a214&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
        }
    },
    async mounted() {
        window.addEventListener("beforeunload", event => {
            const gaUid = this.$store.state.user.id || false;
            if (gaUid) {
                window.ga("set", "dimension1", gaUid);
            }
            window.ga("set", "page", "/sessionend");
            window.ga("set", "dimesion2", JSON.stringify(new Date().getTime() / 1000));
            window.ga("send", "pageview");
            delete event.returnValue;
        });
        // check for unsupported browser
        const browser = this.$ua.browser();
        const browserVersion = this.$ua.browserVersion() || "0";
        if (!["Chrome", "Firefox", "Safari"].includes(browser)) {
            if (browser === "Edge" && Number(browserVersion.split(".")[0]) >= 79) {
                // do nothing
            } else {
                this.$refs.browserWarning.show();
            }
        }
        if (!this.user.sample) {
            // initialize HubSpot live chat
            this.runHubSpotIdentification();
        } else {
            const tutorial = localStorage.getItem("freeTrialVideo");
            if (!tutorial) {
                this.$bvModal.show("free-trial-video");
            }
        }
        const workbox = await window.$workbox;
        if (workbox) {
            workbox.addEventListener("installed", event => {
                if (event.isUpdate) {
                    this.$bvToast.show("update-toast");
                }
            });
        }
        const now = Date.now();
        if (now > this.user.exp * 1000) {
            this.$bvModal.show("session_timeout");
        }
        setTimeout(() => {
            this.$churnZero.init(this.user.hubspot_account, this.user.email);
        }, 2000);
    }
};
